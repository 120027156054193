import * as React from "react";
import { useState, useEffect } from "react";
import { useGetAccountInfo } from "@elrondnetwork/dapp-core/hooks";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
} from "@elrondnetwork/erdjs";
import { ApiNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import { contractAddress, apiAddress } from "config";

export const TopInfo = () => {
	const { address, account } = useGetAccountInfo();

	const proxy = new ProxyNetworkProvider("https://gateway.elrond.com");

	const [votingPower, setVotingPower] = useState(0);

	function base64ToHexFunc(str: string): string {
		const encodedData = atob(str);
		let result = "";
		for (let i = 0; i < encodedData.length; i++) {
			const hex = encodedData.charCodeAt(i).toString(16);
			result += hex.length === 2 ? hex : "0" + hex;
		}
		return result.toUpperCase();
	}

	async function getVotingPower() {
		//Get rewards
		var query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getUserVotingPower"),
			args: [new AddressValue(new Address(address))],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }: { returnData: any }) => {
				const decodedData = returnData.map((x: any) => x);
				const n = parseInt(base64ToHexFunc(decodedData[0]), 16);
				setVotingPower(isNaN(n) ? 0 : n);
			})
			.catch((err: any) => {
				console.error("Unable to call VM query", err);
			});
	}

	useEffect(() => {
		getVotingPower();
	}, []);

	return (
		<div className="text-white" data-testid="topInfo">
			<div className="mb-1">
				<span className="opacity-6 mr-1">Your address:</span>
				<span data-testid="accountAddress"> {address}</span>
			</div>
			<div className="mb-4">
				<span className="opacity-6 mr-1">Contract address:</span>
				<span data-testid="contractAddress"> {contractAddress}</span>
			</div>
			<div>
				<h3 className="py-2">
					Your voting power: <b>{votingPower} votes</b>
				</h3>
			</div>
			<hr />
			<h6>Vote is open until Saturday 19 November 2022 12:00:00 UTC</h6>
			<div>
				<h1>Proposal</h1>
				As you may be aware, the only way for a staking provider to expand is to
				add nodes to the queue, where they will not receive rewards until they
				become active. We currently have one node in the queue and were
				considering adding another, but we believe it would be wise to ask you
				as well because we could not have arrived at this point without your
				support. If we decide to add another node, the overall APR will decrease
				to 9 percent, and we will increase the lkmex reward from 1k to 1,5k to
				compensate a little bit.
			</div>
			<hr />
			<div>
				<h1>Votes:</h1>
			</div>
		</div>
	);
};
