import * as React from "react";
import { useEffect, useState } from "react";
import { useGetPendingTransactions } from "@elrondnetwork/dapp-core/hooks/transactions/useGetPendingTransactions";
import { sendTransactions } from "@elrondnetwork/dapp-core/services";
import { refreshAccount } from "@elrondnetwork/dapp-core/utils";
import { useGetAccountInfo } from "@elrondnetwork/dapp-core/hooks";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	Address,
	Query,
	AddressValue,
	ContractFunction,
} from "@elrondnetwork/erdjs";
import { ProxyNetworkProvider } from "@elrondnetwork/erdjs-network-providers";
import moment from "moment";
import { contractAddress, apiAddress } from "config";

export const Actions = () => {
	const { address, account } = useGetAccountInfo();
	const { hasPendingTransactions } = useGetPendingTransactions();

	const proxy = new ProxyNetworkProvider("https://gateway.elrond.com");

	const [voted, setVoted] = useState<boolean>(false);

	const [votesA, setVotesA] = useState<number>(0);
	const [votesB, setVotesB] = useState<number>(0);

	const [votingPower, setVotingPower] = useState(0);

	const /*transactionSessionId*/ [, setTransactionSessionId] = useState<
			string | null
		>(null);

	async function getVotingPower() {
		var query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getUserVotingPower"),
			args: [new AddressValue(new Address(address))],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }: { returnData: any }) => {
				const decodedData = returnData.map((x: any) => x);
				const n = parseInt(base64ToHexFunc(decodedData[0]), 16);
				setVotingPower(isNaN(n) ? 0 : n);
			})
			.catch((err: any) => {
				console.error("Unable to call VM query", err);
			});
	}

	const voteA = async () => {
		if (votingPower === 0) {
			return;
		}
		const tx = {
			value: 0,
			data: "vote@01",
			receiver: contractAddress,
			gasLimit: "5000000",
		};
		await refreshAccount();

		const { sessionId, error } = await sendTransactions({
			transactions: tx,
			transactionsDisplayInfo: {
				processingMessage: "Voting...",
				errorMessage: "An error has occured :(",
				successMessage: "Thanks for voting! :)",
			},
		});
		if (sessionId != null) {
			setTransactionSessionId(sessionId);
		}
	};

	const voteB = async () => {
		if (votingPower === 0) {
			return;
		}
		const tx = {
			value: 0,
			data: "vote@02",
			receiver: contractAddress,
			gasLimit: "5000000",
		};
		await refreshAccount();

		const { sessionId, error } = await sendTransactions({
			transactions: tx,
			transactionsDisplayInfo: {
				processingMessage: "Voting...",
				errorMessage: "An error has occured :(",
				successMessage: "Thanks for voting! :)",
			},
		});
		if (sessionId != null) {
			setTransactionSessionId(sessionId);
		}
	};

	function base64ToHexFunc(str: string): string {
		const encodedData = atob(str);
		let result = "";
		for (let i = 0; i < encodedData.length; i++) {
			const hex = encodedData.charCodeAt(i).toString(16);
			result += hex.length === 2 ? hex : "0" + hex;
		}
		return result.toUpperCase();
	}

	async function getVoted() {
		var query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getUserVoted"),
			args: [new AddressValue(new Address(address))],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }: { returnData: any }) => {
				const decodedData = returnData.map((x: any) => x);
				const userVoted = decodedData[0] != "";
				setVoted(userVoted);
			})
			.catch((err: any) => {
				console.error("Unable to call VM query", err);
			});
	}

	async function getVotes() {
		var query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getVotesA"),
			args: [],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }: { returnData: any }) => {
				const decodedData = returnData.map((x: any) => x);
				const n = parseInt(base64ToHexFunc(decodedData[0]), 16);
				setVotesA(isNaN(n) ? 0 : n);
				console.log("A", n);
			})
			.catch((err: any) => {
				console.error("Unable to call VM query", err);
			});
		var query = new Query({
			address: new Address(contractAddress),
			func: new ContractFunction("getVotesB"),
			args: [],
		});
		proxy
			.queryContract(query)
			.then(({ returnData }: { returnData: any }) => {
				const decodedData = returnData.map((x: any) => x);
				const n = parseInt(base64ToHexFunc(decodedData[0]), 16);
				setVotesB(isNaN(n) ? 0 : n);
				console.log("B", n);
			})
			.catch((err: any) => {
				console.error("Unable to call VM query", err);
			});
	}

	useEffect(() => {
		getVotingPower();
		getVoted();
		getVotes();
	}, []);

	function getCurrentTimestamp() {
		return Date.now();
	}

	return (
		<div className="d-flex mt-4 justify-content-center">
			{voted ? (
				<>
					<div className="action-btn">
						<h2>{Math.round((votesA / (votesA + votesB)) * 100)} %</h2>
						<p className="text-white text-decoration-none">
							Do nothing and maintain current APR plus 1k lkmex
						</p>
					</div>
					<div className="action-btn ml-5">
						<h2>{Math.round((votesB / (votesA + votesB)) * 100)} %</h2>
						<p className="text-white text-decoration-none">
							Add a new node, approximately 9% APR plus 1.5k lkmex
						</p>
					</div>
				</>
			) : getCurrentTimestamp() < 1668859200000 ? (
				<>
					<div className="action-btn" onClick={voteA}>
						<button className="btn" disabled={votingPower === 0}>
							<FontAwesomeIcon icon={faArrowUp} className="text-primary" />
						</button>
						<a href="/" className="text-white text-decoration-none">
							Do nothing and maintain current APR plus 1k lkmex
						</a>
					</div>
					<div className="action-btn ml-5" onClick={voteB}>
						<button className="btn" disabled={votingPower === 0}>
							<FontAwesomeIcon icon={faArrowUp} className="text-primary" />
						</button>
						<a href="/" className="text-white text-decoration-none">
							Add a new node, approximately 9% APR plus 1.5k lkmex
						</a>
					</div>
				</>
			) : (
				<>
					<h2 className="action-btn">Vote is closed</h2>
					<div className="action-btn">
						<h2>{Math.round((votesA / (votesA + votesB)) * 100)} %</h2>
						<p className="text-white text-decoration-none">
							Do nothing and maintain current APR plus 1k lkmex
						</p>
					</div>
					<div className="action-btn ml-5">
						<h2>{Math.round((votesB / (votesA + votesB)) * 100)} %</h2>
						<p className="text-white text-decoration-none">
							Add a new node, approximately 9% APR plus 1.5k lkmex
						</p>
					</div>
				</>
			)}
		</div>
	);
};
